<template>
  <div id="misc">
    <div class="page-title text-center justify-center px-5">
      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="100"
          margin="100"
          src="@/assets/images/misc/loading-spinner-dark.gif"
        ></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import { AWSAppSyncClient } from 'aws-appsync'
import { v4 as uuid } from 'uuid'
import * as QuasrHelper from '@quasr-io/helper'
import { mdiAlert } from '@mdi/js'
import * as jose from 'jose'
import axios from 'axios'
import IdTokenVerifier from 'idtoken-verifier'
import * as pkceChallenge from 'pkce-challenge'
import * as RandExp from 'randexp'
import store from '../store'

export default {
  setup() {
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const TEN_CLAIM = API_ENV === 'prod' ? 'https://api.quasr.io/claims/ten' : `https://api-${API_ENV}.quasr.io/claims/ten`
    const QUASR_ENV = QuasrHelper.getTenantAndClient(uiMode === 'admin')

    let basic_auth = ''
    basic_auth = '' // needed for tsc
    /* eslint-disable */
    /* eslint-enable */
    const verifier = new IdTokenVerifier({
      issuer: API_ENV === 'prod' ? `https://${QUASR_ENV.tenant_id}.api.quasr.io` : `https://${QUASR_ENV.tenant_id}.api-${API_ENV}.quasr.io`,
      audience: QUASR_ENV.client_id,
      jwksURI: API_ENV === 'prod' ? `https://${QUASR_ENV.tenant_id}.api.quasr.io/.well-known/jwks.json` : `https://${QUASR_ENV.tenant_id}.api-${API_ENV}.quasr.io/.well-known/jwks.json`,
    })

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      TEN_CLAIM,
      basic_auth,
      verifier,
      uiMode,
    }
  },

  /**
   * data
   */
  data() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },

  /**
   * created
   */
  async created() {
    if (!this.QUASR_ENV.tenant_id) {
      document.location.href = 'https://quasr.io'
    } else if (document.location.pathname.endsWith('/login')) {
      this.redirectToLogin('login', false)
    } else if (document.location.pathname.endsWith('/logout')) {
      this.redirectToLogin('login', true)
    } else {
      this.checkUserStatus()
    }
  },

  /**
   * methods
   */
  methods: {

    /**
     * checkUserStatus
     */
    async checkUserStatus() {
      // get "state" and "code" from query params, if any
      const searchParams = new URLSearchParams(document.location.search)

      if (searchParams.get('code')) {
        try {
          const state = searchParams.get('state')
          if (state !== sessionStorage.getItem(`state.${this.QUASR_ENV.tenant_id}`)) {
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/error?msg=State mismatch`)

            return
          }

          const accessToken = await this.exchangeCodeForToken(searchParams.get('code'))
          this.getTokenExp(accessToken) // set expiration in localStorage
          localStorage.setItem(`${this.QUASR_ENV.tenant_id}.access_token`, accessToken)
          this.verifyIdToken(searchParams.get('id_token'))
        } catch (err) {
          this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/error?msg=Unexpected Error`)
        }
      } else {
        if (!store.getAppSyncClient) store.getAppSyncClient = this.initAppSyncClient
        const loginStatus = QuasrHelper.checkLoginStatus(this.QUASR_ENV.tenant_id)
        this.initAppSyncClient()
        if (loginStatus.logged_in) {
          if (document.location.hostname.startsWith('admin')) {
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/dashboard`)
          } else {
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/billing`)
          }
        } else {
          this.redirectToLogin()
        }
      }
    },

    /**
     * initAppSyncClient
     */
    initAppSyncClient() {
      localStorage.removeItem('reduxPersist::appsync-metadata')
      localStorage.removeItem('reduxPersist::appsync')
      localStorage.removeItem('reduxPersist::offline')
      // eslint-disable-next-line
      if (true || !store.appSyncClient) {
        const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
        const QUASR_ENV = QuasrHelper.getTenantAndClient(uiMode === 'admin')
        const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
        store.appSyncClient = new AWSAppSyncClient({
          disableOffline: true,
          cacheOptions: {
            addTypename: false,
          },
          offlineConfig: {
            keyPrefix: uuid(),
          },
          url: QuasrHelper.getGraphQLUri(),
          region: 'eu-central-1',
          auth: {
            type: 'OPENID_CONNECT',
            jwtToken: async () => 'Bearer '.concat(loginStatus.access_token),
          },
        }) // // https://stackoverflow.com/questions/59743243/aws-appsync-javascript-query-example-and-input-syntax
      }

      return store.appSyncClient
    },

    /**
     * redirectToLogin
     */
    redirectToLogin(mode, isLogout) {
      // generate pkce + nonce + state
      const pkce = pkceChallenge.default()
      sessionStorage.setItem(`code_verifier.${this.QUASR_ENV.tenant_id}`, pkce.code_verifier)
      const nonce = new RandExp('[a-zA-Z0-9]{43}').gen()
      sessionStorage.setItem(`nonce.${this.QUASR_ENV.tenant_id}`, nonce)
      const state = new RandExp('[a-zA-Z0-9]{43}').gen()
      sessionStorage.setItem(`state.${this.QUASR_ENV.tenant_id}`, state)
      const account_scope = encodeURIComponent(this.API_ENV === 'prod' ? 'https://api.quasr.io/scopes/account' : `https://api-${this.API_ENV}.quasr.io/scopes/account`)
      const admin_scope = encodeURIComponent(this.API_ENV === 'prod' ? 'https://api.quasr.io/scopes/admin' : `https://api-${this.API_ENV}.quasr.io/scopes/admin`)
      window.location.href = (this.API_ENV === 'prod' ? `https://${this.QUASR_ENV.tenant_id}.api.quasr.io/oauth2/authorize?response_type=code id_token&state=` : `https://${this.QUASR_ENV.tenant_id}.api-${this.API_ENV}.quasr.io/oauth2/authorize?response_type=code id_token&state=`)
        .concat(state)
        .concat('&nonce=')
        .concat(nonce)
        .concat('&redirect_uri=')
        .concat(document.location.origin)
        .concat(encodeURIComponent(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}`))
        .concat(isLogout ? '&prompt=login' : '')
        .concat(mode ? `&mode=${mode}` : '')
        .concat('&client_id=')
        .concat(this.QUASR_ENV.client_id)
        .concat('&scope=openid')
        .concat(document.location.origin.includes('account') ? ` ${account_scope}` : '')
        .concat(document.location.origin.includes('admin') ? ` ${account_scope} ${admin_scope}` : '')
        .concat('&code_challenge=')
        .concat(pkce.code_challenge)
        .concat('&code_challenge_method=S256')
    },

    /**
     * exchangeCodeForToken
     */
    async exchangeCodeForToken(code) {
      const response = await axios({
        method: 'POST',
        url: this.API_ENV === 'prod' ? `https://${this.QUASR_ENV.tenant_id}.api.quasr.io/oauth2/token` : `https://${this.QUASR_ENV.tenant_id}.api-${this.API_ENV}.quasr.io/oauth2/token`,
        data: {
          client_id: this.QUASR_ENV.client_id,
          grant_type: 'authorization_code',
          code_verifier: sessionStorage.getItem(`code_verifier.${this.QUASR_ENV.tenant_id}`),
          code,
        },
        headers: this.API_ENV === 'prod' ? {} : {
          Authorization: `Basic ${this.basic_auth}`,
        },
      })
      const { access_token } = response.data

      // const { id_token } = response.data
      // const { refresh_token } = response.data

      return access_token
    },

    /**
     * getTokenExp
     */
    getTokenExp(token) {
      const payload = jose.decodeJwt(token)
      if (payload) {
        localStorage.setItem(`${this.QUASR_ENV.tenant_id}.exp`, payload.exp)
      } else {
        this.redirectToLogin()
      }
    },

    /**
     * verifyIdToken
     */
    async verifyIdToken(idToken) {
      const nonce = sessionStorage.getItem(`nonce.${this.QUASR_ENV.tenant_id}`)

      this.verifier.verify(idToken, nonce, (error, payload) => {
        if (error) {
          localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.access_token`)
          this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/error`)

          return false
        }

        // local storage
        localStorage.setItem(`${this.QUASR_ENV.tenant_id}.sub`, payload.sub)
        localStorage.setItem(`${this.QUASR_ENV.tenant_id}.ten`, this.QUASR_ENV.tenant_id)

        // local
        if (document.location.hostname.startsWith('admin')) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/dashboard`)
        else this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/billing`)

        // remember the last used tenant and client for the admin UI, so that
        // if the admin UI url is called without params, it will used those
        // as fallback
        if (this.uiMode === 'admin') {
          localStorage.setItem('last_used_tenant', this.QUASR_ENV.tenant_id)
          localStorage.setItem('last_used_client', this.QUASR_ENV.client_id)
        }

        return true
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
